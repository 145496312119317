import { notification } from "antd";
import { ArgsProps as AntdNotificationArgsProps } from "antd/lib/notification";
import React from "react";

/**
 * Antd's notification.success() with some defaults.
 * Default duration: 5s
 */
export const growlSuccess = (message: React.ReactNode, args?: AntdNotificationArgsProps): void => {
  if (!args?.message) {
    args = { ...args, message };
  }
  if (!args.duration) {
    // default to 5s
    args = { ...args, duration: 5 };
  }
  notification.success(args);
};

// Accepts react components, a string, or an object such as a javascript exception that has toString
// 2nd parameter is optional antd notification args
export const growlWarning = (
  message: React.ReactNode | React.ReactNode[] | { toString: () => string },
  args?: Partial<AntdNotificationArgsProps>
): void => {
  // If not a react-like object
  if (!(message instanceof Array) && !React.isValidElement(message)) {
    message = message + ""; // trigger toString()
  }
  const theArgs = Object.assign(
    {
      message: <>{message}</>,
      duration: 7,
    },
    args || {}
  );
  notification.warning(theArgs);
};
